<template>
  <v-app>
    <v-banner
      single-line
      class="banner"
    >
      <v-carousel class="h5-carousel-new carousel-banner" height="100%"
                  :show-arrows="false" :cycle="true"
                  hide-delimiter-background>
        <v-carousel-item v-for="(v,index) in bannerList" :key="index" :href="v.link" >
          <v-img :src="v.bannerImg" width="100%" height="100%" contain>
            <template v-slot:placeholder><img-placeholder></img-placeholder></template>
          </v-img>
          <div class="h5-banner-info">
            <div class="width-per-100" style="margin-top: -60px;">
              <div class="h5-banner-title font-size-h5banner-title">{{v.title}}</div>
              <!--<div class="h5-banner-text">{{v.tip}}</div>-->
            </div>
          </div>
        </v-carousel-item>
      </v-carousel> 
    </v-banner>
    <!-- 为企业赋能 共同创造元宇宙世界 -->
    <v-card flat class="h5model_list1">
      <v-card-title class="justify-center font-size-20 color-333 pa-0 mt-6 line-height-28">为企业赋能 共同创造元宇宙世界</v-card-title>
      <div class="h5-public-title">CREATE A METAVERSE WORLD</div>
      <div class="px-3">
        <v-row class="justify-center mt-0">
          <v-col v-for="(v,k) in modelList1" :key="k" cols="4" class="pa-0">
            <div class="model_item">
              <img class="item_img" :src='v.icon' />
              <div class="item_info">
                <v-card-title class="justify-center reset-title font-size-12 font-weight-300">{{ v.title }}</v-card-title>
                <!--<v-card-text class="reset-text">{{ v.text }}</v-card-text>-->
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <!-- FTE 3D引擎 -->
    <v-card color="#f8faff" flat class="py-6 position-r h5model_list2">
      <v-card-title class="justify-center font-size-20 pa-0 line-height-28">FTE 3D引擎</v-card-title>
      <div class="h5-public-title">FTE 3D ENGINE</div>
      <v-card-text class="text-align-center pa-0">自研三维引擎，可视化便捷操作，让你快速创作虚拟内容</v-card-text>
      <div class="px-3 mt-3">
        <v-row class="justify-center ma-0">
          <v-col v-for="(v,k) in modelList2" :key="k"  class="pa-0">
            <div class="model_item">
              <img class="item_img" :src='v.img'/>
              <div class="item_info">
                {{ v.text }}
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <!-- aPaaS引擎 -->
    <v-card class="px-4 py-6 h5model_list3" flat>
      <v-card-title class="justify-center font-size-20 pa-0 line-height-28">aPaaS引擎</v-card-title>
      <div class="h5-public-title">aPaaS ENGINE</div>
      <v-card-text class="pa-0 text-align-center">强大的工具让元宇宙内容的创作得心应手</v-card-text>
      <div class="mt-3">
        <v-row>
          <v-col v-for="(v,rowIndex) in modelList3" :key="rowIndex" cols="6" style="padding: 10px">
            <v-card class="pa-0 d-flex h5-card" min-height="90" width="100%" flat>
              <div class="model_item">
                <img class="item_img" :src='v.icon' alt/>
                <div class="item_info">
                  <v-card-title class="justify-center reset-title font-size-16">{{ v.title }}</v-card-title>
                  <v-card-text class="reset-text">{{v.text}}</v-card-text>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <!-- 数字生态 -->
    <v-card color="#f8faff" flat class="py-6 position-r h5model_list4">
      <v-card-title class="justify-center font-size-20 pa-0 line-height-28">数字生态</v-card-title>
      <div class="h5-public-title">DIGITAL ECOLOGY</div>
      <div class="mt-3 px-3">
        <v-row class="justify-center">
          <v-col v-for="(v,k) in modelList4" :key="k" class="pa-0" cols="6" style="padding: 0 10px">
            <div class="model_item">
              <img class="item_img" :src='v.icon' alt/>
              <div class="item_info">
                <v-card-title class="justify-center reset-title font-size-16">{{ v.title }}</v-card-title>
                <v-card-text class="reset-text">{{ v.text }}</v-card-text>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <!-- 合作伙伴 -->
    <v-card class="py-6" flat>
      <v-card-title class="justify-center font-size-20 pa-0 line-height-28">合作伙伴</v-card-title>
      <v-img class="mt-3" width="100%" contain src="https://h5.ophyer.cn/official_website/other/num-partner.jpg">
        <template v-slot:placeholder><img-placeholder></img-placeholder></template>
      </v-img>
    </v-card>
  </v-app>
</template>

<script>
export default {
  name: 'Home',
  components: {
  },
  data() {
    return {
      bannerList: [
        {
          bannerImg: 'https://h5.ophyer.cn/official_website/banner/num-banner-h5-1.png',
          title: "让人类进入元宇宙时代",
          titleColor:"#FFFFFF",
          tip: "SaaS产品矩阵，3D引擎，AI技术以及强大的XR硬件体系为企业赋能，让我们携手共进，一起迎接元宇宙",
        },
        {
          bannerImg: 'https://h5.ophyer.cn/official_website/banner/num-banner-h5-2.png',
          title: "为企业打造专属元宇宙空间",
          titleColor:"#FFFFFF",
          tip: "让每一个企业都拥有属于自己的元宇宙空间，支持在任意元宇宙世界中投放",
        },
        {
          bannerImg: 'https://h5.ophyer.cn/official_website/banner/num-banner-h5-3.png',
          title: "飞天元宇宙",
          titleColor:"#FFFFFF",
          tip: "开放沉浸的3D虚拟世界，720度内部VR全景运用你的想象力，让虚拟与现实完美融合",
        },
        {
          bannerImg: 'https://h5.ophyer.cn/official_website/banner/num-banner-h5-saas.png',
          title: "万目云 · SaaS平台",
          titleColor:"#FFFFFF",
          tip: "专属科技，赋能商业，让生产更效率，让运营更智慧",
        },
      ],
      modelList1: [
        {
          icon: "https://h5.ophyer.cn/official_website/other/num_home_img_01.png",
          title: "随心创作",
          text: "3D引擎，aPaaS引擎支撑，丰富的资源内容加持，让元宇宙内容创作更随心"
        },
        {
          icon: "https://h5.ophyer.cn/official_website/other/num_home_img_2.png",
          title: "逼真的效果",
          text: "强大的渲染能力，让材质贴图，光效等画面细节都逼真无比"
        },
        {
          icon: "https://h5.ophyer.cn/official_website/other/num_home_img_3.png",
          title: "沉浸式交互",
          text: "手势，面部表情，物理按键，全息操作面板，让交互更沉浸"
        }
      ],
      modelList2: [
        {
          img: "https://h5.ophyer.cn/official_website/other/num_home_img_4.png",
          text: "强大的功能，丰富的资源助力高效创作"
        },
        {
          img: "https://h5.ophyer.cn/official_website/other/num_home_img_5.png",
          text: "出色的渲染能力，任何效果都不在话下"
        },
      ],
      modelList3: [
        {
          icon: "https://h5.ophyer.cn/official_website/icon/num-icon-apaas1.png",
          title: "元宇宙内容生产",
          text: "丰富的模板资源简单易操作的界面让内容生产更简单"
        },
        {
          icon: "https://h5.ophyer.cn/official_website/icon/num-icon-apaas2.png",
          title: "大数据",
          text: "大数据能力支持千人千策智能归因有效帮助客户拓展潜客"
        },
        {
          icon: "https://h5.ophyer.cn/official_website/icon/num-icon-apaas3.png",
          title: "AI",
          text: "AI技术赋能让广告投放更智能更精准"
        },
        {
          icon: "https://h5.ophyer.cn/official_website/icon/num-icon-apaas4.png",
          title: "场景",
          text: "不断挖掘更多场景为更多行业赋能"
        }
      ],
      modelList4: [
        {
          icon: "https://h5.ophyer.cn/official_website/icon/num-icon-surround1.png",
          title: "三维引擎",
          text: "FTE引擎及Web3D引擎让产业数字化升级有坚实的基础"
        },
        {
          icon: "https://h5.ophyer.cn/official_website/icon/num-icon-surround4.png",
          title: "视觉与交互算法",
          text: "面部识别，手势识别，场景定位，物品识别，边缘识别，虚拟现实融合"
        },
        {
          icon: "https://h5.ophyer.cn/official_website/icon/num-icon-surround2.png",
          title: "数字资产",
          text: "通过其标准化的方法使企业能够更高效的评估企业数字资产"
        },
        //{
        //  icon: "https://h5.ophyer.cn/official_website/other/num_home_img_15.png",
        //  title: "内容推广",
        //  text: "自媒体，微信生态，各大地图及搜索引擎，均已支持"
        //},
        {
          icon: "https://h5.ophyer.cn/official_website/icon/num-icon-surround3.png",
          title: "行业应用",
          text: "媒体运营，电商，企业展示，文旅，房地产，职业培训，工厂"
        },
      ],
      industrySolutions: [
        {
          tabTitle: '电商零售',
          img: 'https://h5.ophyer.cn/official_website/other/home-tab-img01-1.png',
          lunTitle: '数字场景构建线上沉浸式购物体验',
          tip: '强交互，重体验，为业务增长助力',
          text: [
            '• 720°可交互场景，轻松体验虚拟购物',
            '• 360环物展示更多商品细节',
            // '• 热点、虚拟导购等特色功能，适配更多运营场景',
            '• 热点、虚拟导购等特色功能，适配更多行业场景',
            // '• 优惠券、转盘等运营玩法，提升GMV及销售额'
          ]
        },
        {
          tabTitle: '房产家居',
          img: 'https://h5.ophyer.cn/official_website/other/home-tab-img02-1.png',
          lunTitle: '房产数字化服务，打破传统看房体验',
          tip: '沉浸式体验，助力潜客转化',
          text: [
            '• VR线上带看，实时在线互动，看房更便捷',
            '• VR场景购物，看房购物两不误',
            '• 精密3D扫描，100%还原三维实景',
          ]
        },
        {
          tabTitle: '智慧文旅',
          img: 'https://h5.ophyer.cn/official_website/other/home-tab-img03-1.png',
          lunTitle: '虚拟现实技术加持，推动文旅数字化转型',
          tip: '科技赋能创意，让景区焕发全新活力',
          text: [
            // '• AR打卡互动游戏，为景区商家引流，促进二消',
            '• AR打卡互动游戏，互动性更强更新颖',
            '• VR虚拟展厅，打造爆款科技虚拟展厅',
            '• 数字景区，搭建完成的科技+文化场景',
          ]
        },
        {
          tabTitle: '智慧教育',
          img: 'https://h5.ophyer.cn/official_website/other/home-tab-img04-1.png',
          lunTitle: '科技与教育结合，助力教育信息化管理',
          tip: '软硬件结合，创新教育体验',
          text: [
            '• 以AR/VR技术，打造沉浸式、互动教学新体验',
            '• 虚拟现实与硬件结合，打造XR未来教室',
            '•  虚拟仿真实验室，赋能培训行业',
          ]
        },
        {
          tabTitle: '汽车出行',
          img: 'https://h5.ophyer.cn/official_website/other/home-tab-img05-1.png',
          lunTitle: '3D模型+VR看车，线上看车新体验',
          tip: '高精度建模，720°全景看车，更多细节表现',
          text: [
            '• 720°看外观，看内饰，线上体验实景看车',
            '•  线上留咨表单，为门店强势引流',
          ]
        }
      ],
      advantageList: [
        {
          title: "海量模板",
          icon: "https://h5.ophyer.cn/official_website/other/h5Home01.png",
          // explain: "海量AR/VR模板一键套用，轻松生成运营内容",
          explain: "海量AR/VR模板一键套用，轻松生成创意内容",
        },
        {
          title: "资源整合",
          icon: "https://h5.ophyer.cn/official_website/other/h5Home02.png",
          // explain: "丰富的运营运营经验，整合头部渠道资源，助力品牌快速落地运营策略",
          explain: "丰富的运营经验，整合头部渠道资源，助力品牌快速落地",
        },
        {
          title: "技术保障",
          icon: "https://h5.ophyer.cn/official_website/other/h5Home03.png",
          explain: "专注AR/VR/AI行业数十年技术沉淀；多项专利技术",
        },
        {
          title: "完善服务",
          icon: "https://h5.ophyer.cn/official_website/other/h5Home04.png",
          explain: "7x24小时五星售后服务，随时随地相应，解除您的后顾之忧",
        }
      ],
      tab: 0,
    }
  },
  watch: {
  },
  created() {
  },
  methods: {
    handleBannerClick: function (info){
      if(info.link){
        window.open(info.link);
      }
    },
    handleTabBtn: function (type){
      const maxTab = this.industrySolutions.length - 1;
      if(type === "prev"){
        if(this.tab === 0){
          this.tab = maxTab;
        }else {
          this.tab--;
        }
      }else if(type === "next"){
        if(this.tab === maxTab){
          this.tab = 0;
        }else {
          this.tab++;
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.banner{
  position: relative;
  ::v-deep .v-banner__wrapper,::v-deep .v-banner__content,::v-deep .v-banner__text{
    padding: 0 !important;
    height: 100%;
  }
  .banner-box {
    margin-top: -100px;
    .banner-text {
      p:nth-child(1) {
        font-size: 82px;
        //font-family: SourceHanSansCN-Regular;
        font-weight: 400;
        color: #fff;
        line-height: 88px;
      }
      p:nth-child(2) {
        margin-top: 25px;
        font-size: 24px;
        font-weight: 100 !important;
        color: #fff;
      }
      .banner-btn {
        margin-top: 90px;
        width: 200px;
        height: 60px;
        background: #0568FD;
        border-radius: 35px;
        font-size: 20px !important;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 60px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
  @media (min-width: 960px) {
    .container {
      max-width: 1200px !important;
    }
  }
  
}
.h5model_list1 {
  width: 100%;
  position: relative;
  .model_item{
    width: 100%;
    background: #FFFFFF;
    border-radius: 16px;
    padding-bottom: 20px;
    text-align: center;
    .item_img{
      width: 80px;
      height: 80px;
      object-fit: contain;
    }
    .item_info{
      margin-top: -10px;
    }
  }
}
.h5model_list2{
  width: 100%;
  position: relative;
  .model_item{
    padding: 0 10px;
    width: 100%;
    .item_img{
      width: 100%;
      object-fit: contain;
    }
    .item_info{
      margin-top: 8px;
      //padding: 0 16px;
      font-size: 14px;
      color: #333333;
      line-height: 1.3;
      text-align: center;
    }
  }
}
.h5model_list3{
  width: 100%;
  position: relative;
  .model_item{
    width: 100%;
    min-height: 184px;
    background: #FFFFFF;
    border-radius: 16px;
    padding: 0 10px;
    text-align: center;
    .item_img{
      width: 80px;
      height: 80px;
      object-fit: contain;
    }
    .item_info{
      margin-top: 8px;
    }
  }
}
.h5model_list4{
  width: 100%;
  position: relative;
  .model_item{
    width: 100%;
    min-height: 190px;
    border-radius: 16px;
    padding: 0 10px;
    text-align: center;
    .item_img{
      width: 80px;
      height: 80px;
      object-fit: contain;
    }
    .item_info{
      margin-top: 10px;
    }
  }
}
</style>
